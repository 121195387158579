import { MotionImage } from "components/MotionImage";
import { motion } from "framer-motion";
import React from "react";

export function PageHeader({ children = null, imageSrc }) {
  return (
    <div className="relative overflow-hidden">
      <div>
        <MotionImage
          className="absolute inset-0 object-cover w-full h-full"
          initial={{ scale: 1, opacity: 0 }}
          animate={{
            scale: 1.05,
            opacity: 1,
            transition: { delay: 0, duration: 3 },
          }}
          src={imageSrc}
        />
        {children ? (
          <div
            className="absolute inset-0"
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)",
            }}
          ></div>
        ) : null}
      </div>
      <div className="relative pt-44 pb-44">
        <div className="container px-6 mx-auto flex flex-wrap flex-col md:flex-row items-center">
          <div className="flex flex-col w-full max-w-4xl mx-auto justify-center items-center text-center px-2">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

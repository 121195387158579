import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

export function MotionImage({ className = "", initial, animate, src }) {
  const imgRef = useRef();
  const [loaded, setLoaded] = useState(false);

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (imgRef.current?.complete) {
      onLoad();
    }
  }, []);

  return (
    <motion.img
      className={className}
      initial={initial}
      animate={loaded ? animate : null}
      onLoad={onLoad}
      ref={imgRef}
      src={src}
    />
  );
}
